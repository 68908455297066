import React, { useState } from 'react';
import { Link } from "react-router-dom";
import clsx from 'clsx';

// assets
import { ReactComponent as AmbrosLogo } from "assets/svg/ambros-logo.svg";

// components
import ThemeControl from 'components/ThemeControl';

function Header() {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className='container'>
      <div className={clsx(
        'header',
        openMenu && 'header_mobile'
      )}>
        <div className='header_logo'>
          <AmbrosLogo />
          <div 
            className={clsx(
              "header_iconMenu",
              openMenu && 'is-show'
            )}
            onClick={() => setOpenMenu(prevState => !prevState)}
          >
            <div className='header_iconMenu_line' />
          </div>
        </div>
        <div className="header_menu">
          <nav className='header_menu_nav'>
            <ul>
              <li className='heading-8'>
                <Link to="">Home</Link>
              </li> 
              <li className='heading-8'>
                <Link to="">Ambros & Ichor</Link>
              </li> 
              <li className='heading-8'>
                <Link to="">Ecosystem</Link>
              </li> 
              <li className='heading-8'>
                <Link to="">Developers</Link>
              </li> 
              <li className='heading-8'>
                <Link to="">Blog</Link>
              </li> 
              <li className='heading-8'>
                <Link to="">Career</Link>
              </li> 
              <li className='header_menu_border' />
              <li className='heading-8'>
                <Link to="">Portal</Link>
              </li> 
              <li className='heading-8'>
                <Link to="">Bridge</Link>
              </li> 
              <li className='heading-8'>
                <Link to="">Explorer</Link>
              </li> 
            </ul>  
          </nav> 
        </div>
        <div className='header_theme'>
          <ThemeControl />
        </div>
      </div>

      <div className={clsx(
        'header_blur',
        openMenu && 'is-show'
      )} />
    </div>
  )
}

export default Header