import React from 'react'

function ButtonSimple({ text = 'Submit', ...props }) {
  return (
    <button type="button" className='buttonSimple' {...props}>
      <h6 className='heading-6'>{text}</h6>
    </button>
  )
}

export default ButtonSimple