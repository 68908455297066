import React, {
  useContext,
  createContext,
  useState,
  useEffect
} from "react";

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const [palette, setPalette] = useState(
    window.sessionStorage.getItem("palette") || "light"
  );

  function handleChangePalette() {
    const newPattle = palette === "light" ? "dark" : "light";
    window.sessionStorage.setItem("palette", newPattle);
    setPalette(newPattle);
  }

  useEffect(() => {
    if (palette === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.removeAttribute("data-theme");
    }
  }, [palette]);

  return (
    <GameContext.Provider
      value={{
        palette,
        handleChangePalette
      }}
    >
      {children}
    </GameContext.Provider>
  )
}

export const useGameContext = () => useContext(GameContext);