/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from "react-router-dom";

// assets
import { ReactComponent as IconTwitter } from "assets/social/icon-twitter.svg";
import { ReactComponent as IconDiscord } from "assets/social/icon-discord.svg";
import { ReactComponent as IconLinkedin } from "assets/social/icon-linkedin.svg";
import { ReactComponent as IconYoutube } from "assets/social/icon-youtube.svg";
import { ReactComponent as IconTelegram } from "assets/social/icon-telegram.svg";
import { ReactComponent as AmbrosLogo } from "assets/svg/ambros-logo.svg";

function Footer() {
  return (
    <footer>
      <div className='container'>
        <div className='footerSocial'>
          <a href=""><IconTwitter /></a>
          <a href=""><IconDiscord /></a>
          <a href=""><IconLinkedin /></a>
          <a href=""><IconYoutube /></a>
          <a href=""><IconTelegram /></a>
        </div>

        <div className='footerSocial_line' />

        <div className='footerMenu'>
          <div className='footerMenu_item'>
            <AmbrosLogo />
          </div>
          <div className='footerMenu_item'>
            <h6 className='heading-8-bold text-color-content-always-light-primary'>Developers</h6>
            <ul>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Explorer</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Dev Docs</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Validators</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Bug Bounty</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Whitepapers</Link>
              </li>
            </ul>
          </div>
          <div className='footerMenu_item'>
            <h6 className='heading-8-bold text-color-content-always-light-primary'>Use Ambros</h6>
            <ul>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Portal</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Bridge</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Staking</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Ambros Ecosystem</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Tokenomics</Link>
              </li>
            </ul>
          </div>
          <div className='footerMenu_item'>
            <h6 className='heading-8-bold text-color-content-always-light-primary'>General</h6>
            <ul>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>About Us</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Blogs</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Careers</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Media kits</Link>
              </li>
              <li className='heading-8'>
                <Link to="" className='text-color-content-secondary'>Contact</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className='footerSocial_line' />

        <div className='footer_copyright'>
          <h6 className='heading-9 text-color-content-always-light-primary'>© 2022 Ambros technology</h6>
        </div>

      </div>  
    </footer>
  )
}

export default Footer