import React from 'react';
import clsx from 'clsx';

// assets
import { ReactComponent as IconLight } from "assets/svg/icon-light.svg";
import { ReactComponent as IconDark } from "assets/svg/icon-dark.svg";

// context
import { useGameContext } from 'context/GameContext';

function ThemeControl() {
  const {palette, handleChangePalette } = useGameContext();

  // dark mode
  function handlePalette() {
    const newPattle = palette === "light" ? "dark" : "light";
    handleChangePalette(newPattle);
  }

  return (
    <div className='themeControl'>
      <div 
        className={clsx(
          "themeControl_box",
          palette === 'light' && 'active'
        )}
        onClick={handlePalette}
      >
        <IconLight />
      </div>
      <div 
        className={clsx(
          "themeControl_box",
          palette === 'dark' && 'active'
        )}
        onClick={handlePalette}
      >
        <IconDark />
      </div>
    </div>
  )
}

export default ThemeControl