import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

// pages
import LandingPage from "pages/LandingPage";

// context
import { GameProvider } from 'context/GameContext';

function App() {
  return (
    <GameProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </Router>
    </GameProvider>
  );
}

export default App;
